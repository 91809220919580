import whatsapp from "../../assests/images/whatsapp.png"
import quote from "../../assests/images/quote.png"

// import ceoimg from "../../assests/images/ceoimg.jpg"
import ceoimg from "../../assests/images/ceoimg.png"


const ContentSectionPage = () => {

    return (<>


        <div className="section-one">
            <div className="content-wrapper" >

                <div className="wrapper-for-arrows">
                    <div className="main-1">
                        <div className="sec-main">
                            <h6>CHAT TO OUR</h6>
                            <h5>EXPERT</h5>
                        </div>

                        <div className="main-2">
                            <div className="sec-main-2" style={{ marginTop: "15px" }}>
                                <h6> CA.K.Rijas.FCA </h6>

                                <h5>Tax consultant</h5>
                                <a href="https://api.whatsapp.com/send/?phone=%2B971529137700&text&type=phone_number&app_absent=0" target="_blank"
                                    rel="noopener noreferrer" style={{textDecoration: 'none'}}><button className="whatsapp-btn"><img src={whatsapp} />Chat Now</button></a>
                            </div>
                            <div style={{ margin: "11px" }}>
                                <img className="img-ceo" src={ceoimg} />
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
















       

        <section>
            <div className="head-main">
                <h5>Recent Developments and Future Outlook for Corporate Tax and VAT in the Dubai,UAE</h5>
                <a href="#contact" style={{textDecoration: 'none'}}><button><img src={quote} />Get a free quote</button></a>
            </div>

            <p className="para-one">
                Corporate tax in the UAE refers to the tax imposed on the profits earned by
                companies operating in the country.  The UAE government has announced the implementation
                of corporate tax beginning on June 1. A taxable person or business must pay 9% corporate tax
                starting with their first fiscal year beginning on or after June 1, 2023, according to the law. The
                introduction of a federal corporate tax in the UAE aims to advance the nation's position as a
                premier global hub for trade and investment and quicken the strategic goal of growth and
                transformation. The idea of corporate taxes also assists in avoiding harmful tax practices and
                achieving international standards for tax transparency.
            </p>
            <p className="para-one"> Value Added Tax (VAT) is a consumption-based tax imposed on the supply of goods  and
                services in the UAE. VAT was introduced in the UAE on January 1, 2018. The standard VAT
                rate in the UAE is 5%. Most goods and services are subject to VAT, with some specific
                exemptions and zero-rated supplies. VAT is collected by registered businesses on behalf of the
                government and is then remitted to the UAE's Federal Tax Authority (FTA) through regular VAT
                returns. Businesses with an annual turnover above the mandatory threshold are required to
                register for VAT and comply with the associated regulations and reporting requirements.</p>



        </section>


        {/* <section>
            <div className="head-main">
                <h5>Recent Developments and Future Outlook for Corporate Tax and VAT in the UAE</h5>
                <button><img src={quote} />Get a free quote</button>
            </div>

            <p>
                Corporate tax in the UAE refers to the tax imposed on the profits earned by
                companies operating in the country.  The UAE government has announced the implementation
                of corporate tax beginning on June 1. A taxable person or business must pay 9% corporate tax
                starting with their first fiscal year beginning on or after June 1, 2023, according to the law. The
                introduction of a federal corporate tax in the UAE aims to advance the nation's position as a
                premier global hub for trade and investment and quicken the strategic goal of growth and
                transformation. The idea of corporate taxes also assists in avoiding harmful tax practices and
                achieving international standards for tax transparency.

               <p>Value Added Tax (VAT) is a consumption-based tax imposed on the supply of goods  and
                services in the UAE. VAT was introduced in the UAE on January 1, 2018. The standard VAT
                rate in the UAE is 5%. Most goods and services are subject to VAT, with some specific
                exemptions and zero-rated supplies. VAT is collected by registered businesses on behalf of the
                government and is then remitted to the UAE's Federal Tax Authority (FTA) through regular VAT
                returns. Businesses with an annual turnover above the mandatory threshold are required to
                register for VAT and comply with the associated regulations and reporting requirements.</p> 
               
            </p>



        </section> */}


    </>)
}
export default ContentSectionPage;