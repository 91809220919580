
import profile from "../../../assests/images/profile.png";
import call from "../../../assests/images/Call.png"
import email from "../../../assests/images/mail.svg"
import location from "../../../assests/images/location.png"
import whatuplogo from "../../../assests/images/logos_whatsapp-icon.svg"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useForm, Controller } from "react-hook-form";

import "./index.css"

const FooterComponentPage=()=>{
  const navigate=useNavigate()
  const { control,register, handleSubmit } = useForm();
  


  
  const handleUserDetails = (values) => {
    const data = {
      fullName: values.fullName,
      // customerLastName: values.lastName,
      email: values.email,
      phone: values.phnnumber,
      type: "Contact",
      description: "null",
      address: "null",
      location: values.location,
      cv: "null",

    }

    axios
      .post("https://api.accruonconsultants.com/accruonConsultantApi/addCustomerContacts", data)
      .then((response) => {
        if (response.data.message == "Success") {
          navigate("thank-you")
          console.log(response)

        }
      })



  }
    return(
    <>
   
    <section className="contact-main text-center" >
    <form onSubmit={handleSubmit(handleUserDetails)}> 
   <div className="form-contain form-group" id="form-contain1">
    
     <h1>Contact Us</h1>
     <p>Please fill in the below form and our consultant will get back to you. </p>
     <div className="input-icons">
    
     <img src={profile} className="icon-m"/>
     <input type="text"   autoComplete="off" placeholder="Enter your name" {...register("fullName")}/>
     </div>
     <div className="input-icons">
     <Controller
        control={control}
        name="phnnumber"
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <PhoneInput 
            {...field}
            inputExtraProps={{
              ref,
              required: true,
              autoFocus: true
            }}
            country={"ae"}
            autoComplete="off"
            enableSearch={true}
          
          />
        )}
      />
     </div>
     <div className="input-icons">
                  <img src={email} className="icon-m" />
                  <input
                   autoComplete="off"
                    placeholder="Email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                    type="email"
                    required
                    className="input"
                  />
                </div>
     <div className="input-icons">
     <img src={location} className="icon-m"/>
     <input type="text"   autoComplete="off" placeholder="Location" {...register("location")}/>
     </div>
     
    
     <div className="b-btn" id="b-btn">
     <button type="submit" className="form-btn">Submit</button>
   </div>
   </div>
   </form>
</section>




 <section>
<div className="last-sec">
<h1>Ready to get started? Contact us!</h1>

<p>Get in Touch Today for a Business Setup in Dubai<a href="tel:+971 52913 7700" style={{color:"black"}}>   +971 52913 7700</a></p>
</div>
<div className="float-whatsapp-btn">
<a href="https://api.whatsapp.com/send/?phone=%2B971529137700&text&type=phone_number&app_absent=0" target="_blank"
                                    rel="noopener noreferrer" style={{textDecoration: 'none'}} class="float" >
    <i class="fa fa-whatsapp my-float" aria-hidden="true"><img style={{width:"27px",textAlign:"center",marginTop:"10px"}} src={whatuplogo}></img> <div class="pushdaddy-button-label" id="pushdaddy-button-label" >WhatsApp Chat</div></i>
  </a>
 
  </div>

 </section>
    </>
    )
}
export default FooterComponentPage;