import NavBarPage from "../components/navbar";
import BannerWithContactPage from "../banner-contact";
import ContentSectionPage from "../content-section";
import OurServicesPage from "../ourservices";
import FooterComponentPage from "../components/footer";
import OurTestimonialsPage from "../testimonials";

import "../../assests/css/style.css"
const LandingHomePageConsultants=()=>{
return(
<>
<NavBarPage></NavBarPage>
< BannerWithContactPage />
<ContentSectionPage/>
<OurServicesPage/>
<OurTestimonialsPage/>
<FooterComponentPage/>
</>
)
}
export default LandingHomePageConsultants;