
import whatapp from "../../assests/images/whatsapp.png"
import quotes from "../../assests/images/quote.png"

const OurServicesPage = () => {
  return (<>


    <section>
      <div className="head-main" id="head-1">
        <h5>Our Services</h5>
        <a href="https://api.whatsapp.com/send/?phone=%2B971529137700&text&type=phone_number&app_absent=0" target="_blank"
          rel="noopener noreferrer" style={{textDecoration: 'none'}}>  <button className="whatsapp-btn"><img src={whatapp} />Chat Now</button></a>
      </div>

      <div className="row testimonial-1">
        <div className="col-md-6 test1-box">
          <div class="test-box">
            <h5>Corporate tax consultants</h5>
            <div className="pop">Popular</div>
          </div>
          {/* <!-- <h6>Corporate tax Registration in UAE</h6> --> */}
          <div className="border-1"></div>
          <p className="para-one">Corporate tax consultants in Dubai provide invaluable expertise and guidance to businesses navigating the complex world of taxation. As Dubai continues to be a thriving hub for businesses of all sizes, these consultants play a pivotal role in helping companies optimize their financial strategies.
           They specialize in understanding the ever-evolving tax laws, regulations, and incentives, ensuring that their clients remain in compliance with all tax obligations while maximizing their financial efficiency. These experts offer tailored solutions that encompass tax planning, return preparation, and comprehensive advisory services. By collaborating with corporate tax consultants in Dubai, businesses can make informed decisions, reduce their tax burdens, and ultimately enhance their financial health in this dynamic and competitive market...</p>

          {/* <!-- <button className="get-btn"><img src="images/right-arrow.svg">Get Now</button> --> */}
        </div>
        <div className="col-md-5 test2-box">
          <div className="test-box">
            <h5>VAT consultants </h5>
            <div className="pop">Popular</div>
          </div>
          {/* <!-- <h6>Corporate tax consultancy in UAE</h6> --> */}
          <div className="border-1"></div>
          <p className="para-one">VAT consultants in Dubai offer invaluable support to businesses in navigating the complexities of Value Added Tax (VAT) in the United Arab Emirates. Since the implementation of VAT in the region, these consultants have become essential for ensuring compliance with tax regulations and optimizing financial strategies.
           They possess a deep understanding of the UAE's VAT laws and provide comprehensive services that include VAT registration, reporting, and advisory assistance.
            By partnering with VAT consultants in Dubai, businesses can streamline their VAT-related processes, manage their tax liabilities efficiently, and make informed decisions about their financial operations. 
            These experts play a pivotal role in helping companies maintain compliance and avoid potential penalties while also helping them enhance their financial health in a highly competitive business landscape in Dubai.</p>
          {/* <!-- <button className="get-btn"><img src="images/right-arrow.svg">Get Now</button> --> */}
        </div>
      </div>
      <div className="row testimonial-1">
        <div className="col-md-5 test2-box">
          <div className="test-box">
            <h5>Book keeping and Accounting services </h5>
            <div className="pop">Popular</div>
          </div>
          {/* <!-- <h6>Corporate tax Advisory  in UAE</h6> --> */}
          <div className="border-1"></div>
          <p className="para-one">Bookkeeping and accounting services in Dubai are vital for businesses seeking to maintain accurate financial records and make informed decisions. In this dynamic and fast-growing business environment, these services play a fundamental role in ensuring transparency, compliance, and financial stability. Professional accountants in Dubai are well-versed in local and international accounting standards, making them invaluable partners for businesses of all sizes. They offer a wide range of services, including data entry, financial statement preparation, auditing, and tax planning. By outsourcing bookkeeping and accounting functions to experts in Dubai, companies can focus on their core operations, reduce the risk of financial errors, and make strategic financial decisions based on accurate and up-to-date information. These services are the backbone of sound financial management, helping businesses thrive and succeed in the competitive Dubai market.</p>
          {/* <!-- <button className="get-btn"><img src="images/right-arrow.svg">Get Now</button> --> */}
        </div>
        <div className="col-md-6 test2-box">
          <div className="test-box">
            <h5>Auditing and Assurance </h5>
            <div className="pop">Popular</div>
          </div>
          {/* <!-- <h6>Corporate tax Return Filing in UAE</h6> --> */}
          <div className="border-1"></div>
          <p className="para-one"> Auditing and assurance services in Dubai are indispensable for ensuring transparency, accountability, and trust in the business landscape of the United Arab Emirates. With the region's growing economy and increasing international investments, these services have gained paramount importance. Professional auditors in Dubai provide a comprehensive range of services, including financial statement audits, internal audits, and compliance assessments. They play a crucial role in helping businesses adhere to local and international accounting and auditing standards, while also identifying and mitigating risks. In a business environment where credibility and financial integrity are paramount, these services not only provide assurance to stakeholders but also help companies enhance their operational efficiency and decision-making. With Dubai's position as a global business hub, auditing and assurance services contribute to maintaining the high standards of corporate governance and financial integrity that are crucial for long-term business success in the region.</p>
          {/* <!-- <button className="get-btn"><img src="images/right-arrow.svg">Get Now</button> --> */}
        </div>
        
      </div>
      <div className="row testimonial-1">
        <div className="col-md-5 test2-box">
          <div className="test-box">
            <h5>UAE company formation</h5>
            <div className="pop">Popular</div>
          </div>
          {/* <!-- <h6>Corporate tax Advisory  in UAE</h6> --> */}
          <div className="border-1"></div>
          <p className="para-one">Company formation services in Dubai, UAE, are in high demand as the region continues to establish itself as a global business and investment hub. These services facilitate the process of establishing new businesses, helping entrepreneurs and investors navigate the intricate regulatory landscape of the United Arab Emirates. Professional company formation experts in Dubai provide invaluable assistance in selecting the right business structure, securing necessary licenses and permits, and ensuring compliance with local and international business regulations. With various free zones and mainland options available, businesses can tailor their setup to suit their specific needs and objectives. These services also encompass guidance on ownership structures, visas, and corporate banking, making the UAE an attractive destination for global entrepreneurs. By leveraging UAE company formation services, businesses can unlock opportunities in a dynamic market, take advantage of tax incentives, and establish a strong foothold in one of the world's most vibrant and economically robust regions.</p>
          {/* <!-- <button className="get-btn"><img src="images/right-arrow.svg">Get Now</button> --> */}
        </div>
        <div className="col-md-6 test2-box">
          <div className="test-box">
            <h5>Business consulting and services</h5>
            <div className="pop">Popular</div>
          </div>
          {/* <!-- <h6>Corporate tax Return Filing in UAE</h6> --> */}
          <div className="border-1"></div>
          <p className="para-one"> Business consulting and services in Dubai provide essential support to companies seeking to thrive in one of the world's most dynamic and competitive business environments. With Dubai's strategic location, international connections, and a diverse economy, businesses often turn to professional consulting services to gain a competitive edge. These services encompass a wide range of offerings, including market research, business strategy development, financial analysis, and regulatory compliance. Expert consultants in Dubai offer valuable insights into market trends, helping businesses make informed decisions and formulate strategies for growth. They also assist with navigating the legal and administrative requirements for setting up and operating businesses, ensuring smooth operations and compliance with local regulations. In a region known for its entrepreneurial spirit and constant innovation, business consulting and services are indispensable for companies looking to thrive in the global marketplace while harnessing the opportunities Dubai offers.</p>
          {/* <!-- <button className="get-btn"><img src="images/right-arrow.svg">Get Now</button> --> */}
        </div>
        
      </div>

    </section>

    <section>
    <div class="row justify-content-center">        
      <div class="col-lg-5 col-md-12 top-img">
          <div class="title-wrapper">
          
            <iframe class="video-one" height src="https://www.youtube.com/embed/cp_3Za-4Fbo?si=PRtfhgpLobVdWsdC?autoplay=1&amp;mute=1&amp;rel=0">
            </iframe>
          </div>
      </div>
      <div class="col-lg-5 col-md-12 md-pb top-img">
      <div class="title-wrapper">
            <iframe class="video-one" height="" src="https://www.youtube.com/embed/MVNYp6BeqoM?si=Q0i0W39Z_-9Azs-L">
            </iframe>
             
            </div>
      </div>
    </div>
   
          </section>

    {/* <!--Strip section--> */}
    <section className="strip-sec">
      <a href="#contact" style={{textDecoration: 'none'}}><button><img src={quotes} />For free consultation</button></a>

    </section>

    {/* <!--strip section end--> */}








































  </>)
}
export default OurServicesPage