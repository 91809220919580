
import formImage from "../../assests/images/form-img.png"
import profile from "../../assests/images/profile.png"
import call from "../../assests/images/Call.png"
import email from "../../assests/images/mail.svg"
import location from "../../assests/images/location.png"
import { useNavigate } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { message } from "antd"
import React, { useState } from "react";
import axios from "axios"
import "./index.css"

const BannerWithContactPage = () => {
  const navigate = useNavigate();
  const { control, register, handleSubmit } = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [phone, setPhone] = useState("");


  const handleUserDetails = (values, phone) => {
    console.log(values)
    console.log(phone)
    const data = {
      fullName: values.fullName,
      // customerLastName: values.lastName,
      email: values.email,
      phone: values.phnnumber,
      type: "Contact",
      description: "null",
      address: "null",
      location: values.location,
      cv: "null",
    }
    axios
      .post("https://api.accruonconsultants.com/accruonConsultantApi/addCustomerContacts", data)
      .then((response) => {
        if (response.data.message == "Success") {
          navigate("Thank-you")
        }
      })
  }
  return (
    <>
      {/* <!-- ========= SECTION ======== --> */}
      {contextHolder}
      <main className="container main-banner" id="contact">
        <div className="content">
          <div className="text">
            <h1>
          Schedule a Discovery Session For Corporate Tax With The Expert
            </h1>
            <p>
              Register Now to Unlock the Secrets of Corporate Tax in the Dubai,UAE
            </p>
          </div>
          <div className="person">
            <form className="form-group" onSubmit={handleSubmit(handleUserDetails)}>
              <div className="form-contain">
                <img src={formImage} className="form-img" />
                <h1>Get in <span>touch</span></h1>
                <p>Get in touch with our expert tax consultants today!. </p>
                <div className="input-icons">
                  <img src={profile} className="icon-m" />
                  <input type="text" autoComplete="off" placeholder="Enter your name" {...register("fullName")} />
                </div>
                <div className="input-icons">
                  <Controller
                    control={control}
                    name="phnnumber"
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                      <PhoneInput
                        {...field}
                        inputExtraProps={{
                          ref,
                          required: true,
                          autoFocus: true
                        }}
                        country={"ae"}
                        autoComplete="off"
                        enableSearch={true}
                      />
                    )}
                  /> 
                </div>
                <div className="input-icons">
                  <img src={email} className="icon-m" />
                  <input
                   autoComplete="off"
                    placeholder="Email"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                    type="email"
                    required
                    className="input"
                  />
                </div>
                <div className="input-icons">
                  <img src={location} className="icon-m" />
                  <input type="text" autoComplete="off" placeholder="Location" {...register("location")} />
                </div>
                <div className="b-btn">
                  <button type="submit" className="form-btn">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <div className="slider-bottom">
      </div>
    </>
  )
}
export default BannerWithContactPage